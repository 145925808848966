.card {
    width: 700px;
    height: 370px;
    background-color: #BFDEFF;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.title {
    width: 80%;
    margin-top: 60px;
    font-size: 40px;
    color: #265999;
    font-weight: 600;
    width: 100%;
}

.body-about-us {
    width: 80%;
    margin-top: 20px;
    font-size: 25px;
    font-weight: 600;
    width: 100%;
}

.content {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* background-color: pink; */
    gap: 20px;
}

@media (max-width: 767px) {
    .title {
        font-size: 20px;
        margin-top: 20px;
    }

    .body-about-us {
        font-size: 15px;
        margin-top: 0;
    }

    .card {
        width: 100%;
        height: auto;
        padding-bottom: 30px;
    }
}