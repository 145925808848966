body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("../fonts/static/Montserrat-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";
  src: local("Montserrat"),
    url("../fonts/static/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "skinny-montserrat";
  src: local("Montserrat"),
    url("../fonts/static/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Hard";
  src: local("Montserrat"),
    url("../fonts/static/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica";
  src: local("Helv"),
    url("../fonts/helvfold/oh/Helvetica\ Neue\ UltraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium-Soft";
  src: local("Helv"),
    url("../fonts/static/Montserrat-SemiBold.ttf") format("truetype");
}