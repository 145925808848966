@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #434449;
  /* color: white; */
}

.content-wrap {
  display: flex;
  flex-direction: column;
  /* height: 500px; */
  min-height: 100vh;
}

.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* min-height: 100vh; */
}

.foot-params {
  margin-top: auto;
}


