.popup {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: fadeIn 0.15s forwards;
}

.visible {
  opacity: 1;
}

.popup-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px;
  width: 85%;
  background-color: #f3f3ed;
  border-radius: 20px;
}

.popup-inner img {
  width: 368px;
  height: 368px;
}

.image-container img {
  overflow: hidden;
}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 37px;
  height: 35.9px;

}
.popup-inner .close-btn:hover {
  content: url('../images/assets/xHover.png');
}

.profile-stack {
  margin-left: 30px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.name {
  font-family: "Montserrat-Medium-Soft";
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  display: flex;
  align-items: center;
  font-variant: all-small-caps;
}

.ask-about {
  font-family: 'Montserrat-Medium-Soft';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  font-variant: all-small-caps;
  color: #37383C;

}

.position {
  margin-top: 0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.year {
  margin-top: 0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.img-profile {
  display: flex;
  flex-direction: horizontal;
}

.abouts {
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
}

.about-item {
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 10px;
  width: auto;
  height: 32px;
  background: #667BC4;
  border-radius: 11px;
  font-family: 'Montserrat-Medium-Soft';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
}

.contact {
  font-family: 'Montserrat-Medium-Soft';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  font-variant: all-small-caps;
  color: #37383C;
}

.contacts {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.contacts img {
  width: 50px;
  height: 50px;
}


.bio {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  
  .popup-inner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 0px;
  }

  .secondary {
    width: 300px;
    height: auto;
    margin-top: 0px;
    display: flex;
    justify-content: center;
  }

  .secondary img {
    width: 80%;
    height: auto;
  }

  .profile-stack {
    margin-left: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }

  .img-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bio {
    font-size: 12px;
  }
  
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}