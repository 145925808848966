
.points-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: calc(1200px * var(--scale));
    margin: 0 auto;
    padding: 0 calc(20px * var(--scale));
    margin-bottom: 5rem;
    gap: calc(4rem * var(--scale));
}

.points {
    color: #F0F0F0;
    font-family: "Helvetica Neue";
    font-size: calc(64px * var(--scale));
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: calc(80px * var(--scale));
    margin-top: calc(1.5rem * var(--scale));
}

.container-title {
    color: #FFF;
    font-family: Medium;
    font-size: calc(2.5rem * var(--scale));
    font-weight: 600;
}

.points-allocation {
    display: flex;
    flex-direction: column;
    gap: calc(3rem * var(--scale));
    margin-bottom: calc(50px * var(--scale));
}

.point-item {
    display: flex;
    gap: calc(20px * var(--scale));
    align-items: center;
}

.point-group {
    display: flex;
    gap: calc(20px * var(--scale));
    align-items: flex-start;
}

.point-descriptions {
    display: flex;
    flex-direction: column;
    gap: calc(10px * var(--scale));
}

.point-value {
    color: #EEB50C;
    text-align: center;
    font-family: Medium;
    font-size: calc(2.5rem * var(--scale));
    font-weight: 600;
}

.point-description {

    color: #FFF;
    text-align: center;
    font-family: skinny-montserrat;
    font-size: calc(2.5rem * var(--scale));
    font-weight: 400;
}

.points-container{
    width: 100%;
}

.rewards-grid {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: calc(50px * var(--scale));
}

.reward-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: calc(1rem * var(--scale));
    padding-bottom: calc(2.25rem *var(--scale));
    border-radius: 0.625rem;
    background: #D9D9D9;
    width: calc(20.25rem * var(--scale));
    height: calc(19.4375rem * var(--scale));
    flex-shrink: 0;
    text-align: center;
}

.reward-title {
    color: #667BC4;
    text-align: center;
    font-family: Hard;
    font-size: calc(2.5rem * var(--scale));
    font-weight: 700;
    white-space: pre-line;
}

.reward-value {
    color: #000;
    text-align: center;
    font-family: Hard;
    font-size: calc(2.5rem * var(--scale));
    font-weight: 600;
}

.view-points {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 2.6rem;
    
}

.view-points h1 {
    color: #FFF;
    text-align: center;
    font-family: Hard;
    font-size: calc(2.25rem * var(--scale));
    font-weight: 600;
    margin: 0;
}

.view-points h2 {
    color: #FFF;
    text-align: center;
    font-family: Medium;
    font-size: calc(2rem * var(--scale));
    font-weight: 600;
    margin: 0;
}

#points-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(3.7rem * var(--scale));
    
}

#points-form input {
    width: calc(26.69rem * var(--scale));
    height: calc(6rem * var(--scale));
    fill: #D9D9D9;
    border-radius: calc(25px * var(--scale));
    border: none;
    color: #F0F0F0;

    font-family: Hard;
    font-size: calc(1.875rem * var(--scale));
    font-weight: 600;
    color: black;

}

#points-form button {
    border-radius: calc(25px * var(--scale));
    border: none;
    color: #F0F0F0;
    font-size: calc(1.875rem * var(--scale));
    border-radius: calc(4rem * var(--scale));
    background: var(--blue-cta-color, linear-gradient(138deg, #C0C0C0 -18.69%, #265999 96.38%));

    display: inline-flex;
    height: calc(4.3125rem * var(--scale)); 
    padding: calc(1rem * var(--scale)) calc(2rem * var(--scale));
    justify-content: center;
    align-items: center;
    gap: calc(0.625rem * var(--scale));;
    flex-shrink: 0;

    font-family: Hard;
    font-size: calc(1.875rem * var(--scale));;
    font-weight: 600;
}


#points-form h3 {
    color: #F0F0F0;
    font-family: "Helvetica Neue";
    font-size: calc(20px * var(--scale));
    margin-top: calc(10px * var(--scale));
}

#points-form h3 {
    color: #F0F0F0;
    font-family: "Helvetica Neue";
    font-size: calc(20px * var(--scale));
    margin-top: calc(10px * var(--scale));
}

@media (max-width: 768px) {

    .points-page{
        gap: calc(4rem * var(--scale));

    }

    .points{
        font-size: calc(15rem * var(--scale));;
    }

    .container-title{
        font-size: calc(8rem * var(--scale));;
    }

    .point-value{
        font-size: calc(5rem * var(--scale));;
    }

    .point-description{
        font-size: calc(4rem * var(--scale));;
    }

    .rewards-grid{
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .reward-card{ 
        width: 80%;
        height: calc(30rem * var(--scale));
    }


    .reward-title{
        font-size: calc(4rem * var(--scale));
    }

    .reward-value{
        font-size: calc(4rem * var(--scale));
    }


    .view-points {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: calc(4rem * var(--scale));
        
    }
    
    .view-points h1 {
        font-size: calc(10rem * var(--scale));;
    }

    .view-points h2 {
        font-size: calc(4rem * var(--scale));;
    }

    #points-form input {

        width: 60%;
        height: calc(8rem * var(--scale));
    }

    #points-form button {
        width: 40%;
        font-size: calc(3rem * var(--scale));
        height: calc(8rem * var(--scale)); 

    }
    
 

    #points-form h3 {
        font-size: calc(6rem * var(--scale));
    }
}

