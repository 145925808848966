.card-container {
    width: 250px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #EEEEE6;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 15px;
}

.card-container:hover {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
}

.image-container {
    overflow: hidden;
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
}

.card-title {
    align-self: stretch;
    text-align: center;
    font-family: "Montserrat-Medium-Soft";
    font-variant: all-small-caps;
    color: #37383C;
    font-weight: 600;
    font-size: 22px;
    line-height: normal;
    padding-top: 5px;
    padding-bottom: 5px;
}

.card-name {
    font-family: "Montserrat-Medium-Soft";
    color: #000;
    font-size: 23px;
    padding-bottom: 17px;
    padding-left: 5px;
    padding-right: 5px;
}

.image-container img {
    overflow: hidden;
    height: 200px;
    width: 200px;
}