body {
    margin: 0;
}

.about-us-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* gap: 100px; */
    gap: calc(6rem * var(--scale));
    padding-bottom: calc(60px * var(--scale));
    margin-bottom: calc(6rem * var(--scale));
    
}

.about-us {
    padding-top: calc(20px * var(--scale));
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    /* font-size: 40px; */
    /* font-weight: 700; */
    justify-content: center;
}

.about-header{
    color: #F0F0F0;
    text-align: center;
    font-family: "Helvetica Neue";
    font-size: calc(3.375rem * var(--scale));
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.heading-academic {
    font-size: calc(40px * var(--scale));
    font-weight: 600;
    color: #265999;
}

.purpose {
    width: 90%;
    margin-top: calc(20px * var(--scale));
    margin-bottom: calc(10px * var(--scale));

    color: #F0F0F0;
    font-family: "Helvetica Neue";
    font-size: calc(2.25rem * var(--scale));
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.purpose-bolded {
    color: #EEB50C;
    font-weight: 700;
}

.pillars {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: calc(7.34rem * var(--scale));
    margin-right: calc(7.34rem * var(--scale));  
    gap: calc(4rem * var(--scale));  
}

.pillar {
    display: flex; 
    flex-direction: column;
    align-items: center;
    width: calc(23.9375rem * var(--scale));
    height: auto;
    padding: calc(3.6875rem * var(--scale)) calc(0.5rem * var(--scale));
    gap: calc(2.5rem * var(--scale));
    border-radius: calc(1rem * var(--scale));
    background: #667BC4;
}

.pillar-icon {
    width: calc(9.375rem * var(--scale));
    height: calc(9.375rem * var(--scale));
}

.pillar-text {
    color: #FFF;
    text-align: center;
    font-family: Hard;
    font-size: calc(3rem * var(--scale));
    font-weight: 700;
    line-height: normal;
}

.stats-bar {
    display: flex;
    width: calc(76.375rem * var(--scale));
    height: calc(12.125rem * var(--scale));
    padding: calc(2.4375rem * var(--scale)) calc(6.8125rem * var(--scale));
    justify-content: center;
    align-items: center;
    gap: calc(9.5rem * var(--scale));
    flex-shrink: 0;
    border-radius: calc(2.25rem * var(--scale));
    background: #667BC4;
    box-shadow: 0px calc(4px * var(--scale)) calc(4px * var(--scale)) 0px rgba(0, 0, 0, 0.25);
}

.stats-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--URMC-Gray, #37383C);
}

.stats-header {
    color: #F0F0F0;
    text-align: center;
    font-family: Montserrat;
    font-size: calc(2rem * var(--scale));
    font-weight: 600;
}

.stats-number {
    color: #F0F0F0;
    text-align: center;
    font-family: Hard;
    font-size: calc(2.8125rem * var(--scale));
    font-weight: 700;
}

.stats-block.bottom {
    font-size: calc(30px * var(--scale));
    font-weight: bold;
}

.pillar-sections {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: calc(100px * var(--scale));
    padding-left: calc(4.5rem * var(--scale));
    padding-right: calc(4.5rem * var(--scale));
    /* margin-left: 5.84rem; */
    padding-top: calc(2rem * var(--scale));
    padding-bottom: calc(2rem * var(--scale));

}

.pillar-section {
    display: flex;
    gap: calc(3rem * var(--scale));
    align-items: center;
}

.section-images{
    display: flex;
    flex-direction: column;
}

.section-content{
    display: flex;
    flex-direction: column;
}

.section-title {
    color: #EEB50C;
    font-family: Montserrat;
    font-size: calc(3.5rem * var(--scale));
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.section-text{
    color: #FFF;
    font-family: Medium;
    font-size: calc(1.75rem * var(--scale));
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.academic-images{
    display: flex;
    flex-direction: column;
    gap: calc(2.69rem * var(--scale));
}

#academic-1{
    width: calc(34.1875rem * var(--scale));
    height: calc(20.5625rem * var(--scale));
    flex-shrink: 0;
}

#academic-2{
    margin-left: calc(4.12rem * var(--scale));
    width: calc(32.25rem * var(--scale));
    height: calc(21.5625rem * var(--scale));
}

.community-images{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: calc(3rem * var(--scale));
    /* padding: 20px; */
}


#community-1{
    width: calc(17.36825re * var(--scale));
    height: calc(15.41556rem * var(--scale));
    flex-shrink: 0;
}

#community-2{
    width: calc(19.11531rem * var(--scale));
    height: calc(16.7rem * var(--scale));
    flex-shrink: 0;
}


#community-3{
    width: calc(18.94rem * var(--scale));
height: calc(14.875rem * var(--scale));
flex-shrink: 0;
}

#community-4{
    width: calc(17.5rem * var(--scale));
height: calc(13.1875rem * var(--scale));
flex-shrink: 0;
}

.profdev-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: calc(3.5rem * var(--scale));
    grid-template-areas: 
        "image1 image3"
        "image2 image3";
}

#profdev-1{
    grid-area: image1;
    width: calc(23.14094rem * var(--scale));
    height: calc(17.35569rem * var(--scale));
    flex-shrink: 0;
}

#profdev-2{
    grid-area: image2;
    width: calc(24.04019rem * var(--scale));
    height: calc(18.03013rem * var(--scale));
    flex-shrink: 0;
    flex-shrink: 0;
}

#profdev-3{
    grid-area: image3;
    width: calc(22.51144rem * var(--scale));
    height: calc(15.01763rem * var(--scale));
    flex-shrink: 0;
    margin: auto;
}

.join-section{
    display: flex;
    flex-direction: column;
    gap: calc(1.75rem * var(--scale));
}

#join-header{
    color: #EEB50C;
    font-family: Hard;
    font-size: calc(3.5rem * var(--scale));
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: calc(5rem * var(--scale));
    margin: 0;
}

#join-button{
    display: flex;
    height: calc(4.3125rem * var(--scale));
    padding: calc(0.25rem * var(--scale)) calc(2rem * var(--scale));
    justify-content: center;
    align-items: center;
    gap: calc(0.625rem * var(--scale));

    border-radius: calc(4rem * var(--scale));
    background: var(--urmc-gold-gradient, linear-gradient(108deg, #DFC856 19.51%, #D38745 71.52%));
    box-shadow: 0px calc(4px * var(--scale)) calc(4px * var(--scale)) 0px rgba(0, 0, 0, 0.25);
    margin: 0;
}

#join-text {
    color: var(--URMC-White, #F9F9F9);
    text-align: center;
    font-family: Hard;
    font-size: calc(1.875rem * var(--scale));
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
}

.section-cards {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: calc(100px * var(--scale));
}

.section-widgets {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: calc(20px * var(--scale));
    grid-template-columns: repeat(2, auto);
}

.widget-row {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    gap: calc(30px * var(--scale));
    flex-grow: 1;
}

.reverse {
  flex-direction: row-reverse;
}

@media (max-width: 768px) {

    .pillar-title {
        font-size: 30px;
    }
    .stats {
        display: none;
    }

    .about-us-page {
        gap: 20px;
    }

    .pillar-support {
        align-items: center;
    }

    .pillars {
        display: none;
    }

    .purpose {
        font-size: 15px;
    }

    .pillar-cards {
        flex-direction: column;
        gap: 20px;
    }

    .widget-row {
        flex-direction: column;
        gap: 20px;
    }
}