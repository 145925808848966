*{
    width: auto;
}


.sponsor-page{
    margin-top: calc(1.5rem * var(--scale));
    margin-bottom: calc(3.47rem * var(--scale));
}

.heading {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 0px;
    color: white;
}

.corporate-sponsors-text {
    color: #F0F0F0;
    font-family: "Helvetica Neue";
    font-size: calc(4rem * var(--scale));
    font-weight: 700;
    margin: 0rem;
}


.thanks{
    /* font-weight: normal;
    font-size: x-large; */

    color: var(--Leadership-Gray-White, #EEEEE6);
    font-family: "Helvetica Neue";
    font-size: calc(1.5625rem * var(--scale));
    font-weight: 400;
}

.sponsor-tiers{
    display: flex;
    flex-direction: column;
    column-gap: normal;
    justify-content: center;
    align-items: center; 
    gap: calc(50px * var(--scale));
    margin-left: calc(50px * var(--scale));
    margin-right: calc(50px * var(--scale));
    margin: calc(2.5rem * var(--scale)) 0rem;
    max-width: 100%;    
}

.img-section{
    display: flex;
    flex-wrap: wrap;    
    align-items: center;
    justify-content: center;
    gap: 2.5%; /* Use percentage-based gap for responsiveness */
    width: 100%; /* Set width as a percentage of the parent or viewport */
    height: 100%; /* Let height adjust automatically based on content */
    position: relative;
    flex-direction: row;
    padding-top: 2%; /* Use percentage-based padding */
}

.sponsor-section {
    width: 90%;   
    height: auto; 
    position: relative;
    border-radius: calc(15px * var(--scale));
    max-width: calc(1360px * var(--scale));
    aspect-ratio: 1360 / 220; 
    overflow: visible;
    align-items: center;
    justify-content: center;

}

 .plat-sponsors {
    border: 0px solid #EEB50C;
    background: #DDEAF0;
} 

 .gold-sponsors {
    border: 0px solid #EEB50C;
    background: #CBA135;
} 

.silver-sponsors {
    border: 0px solid #C0C0C0;
    background: rgba(192, 192, 192, 0.50);
}

.bronze-sponsors {
    background: rgba(205, 127, 50, 0.40);
} 
              
.buttons {
    padding: calc(30px * var(--scale));
}
.linkedin {
    width: auto;
    height: calc(50rem * var(--scale));
}

.bloomberg {
    width: auto;
    height: calc(3.9rem * var(--scale));
}

.roblox {
    width: auto;
    height: calc(5rem * var(--scale));
}

.janestreet {
    width: auto;
    height: calc(15rem * var(--scale));
}

.uber {
    width:15%;
    height:25%;
}

.ey {
    height: calc(7.6rem * var(--scale));
    width: auto;
    margin-bottom: 2%;
}

.duolingo {
    /* width:10%; */
    height:50%;
}

.logo {
    max-width: 60%;
    max-height: 60%;
    text-align: center;
    position: static;

}

.btn-1{
    background: var(--blue-cta-color, linear-gradient(138deg, #C0C0C0 -18.69%, #265999 96.38%));
}

@media (max-width: 768px) {

    .sponsor-page{
        padding: 0 calc(3rem * var(--scale));
        
    }

    .corporate-sponsors-text {
        font-size: calc(10rem * var(--scale));;
    }
    .thanks{
        font-size: calc(5rem * var(--scale));;

    }

    .btn-small{
        font-size: calc(3rem * var(--scale));        
    }

    .linkedin{
        width: calc(50rem * var(--scale));;
        height: auto;
       
    }


    .logo{
        width: auto;
        height: auto;
        max-width: 80%;
        max-height: 80%;
        object-fit: contain;
    }




    .bronze-logo{
        max-height: 30%;
    }

    .silver-logo{
        max-height: 50%;
    }

 
    .sponsor-tiers{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;    
        column-gap: normal;
        justify-content: center;
        align-items: center; 
        margin-left: 50px;
        margin-right: 50px;
        max-width: 100%;    
    }
    
     .sponsor-section{
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        width: 100%;
        height: calc(40rem * var(--scale));
        position: relative;
     }

     .img-section{
        padding-top: 8%;
        height: 100%;
     }

    .plat-sponsors .img-section{
        padding-top: 0%;
    }

    .gold-sponsors .img-section{
        padding-top: 0%;
    }


    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .sponsor-page .btn{
       font-size: 24px;
       width: 80%;
       height: 100%;
    }


}

