* {
    box-sizing: border-box;
}

:root {
    --scale: 1;
}

.foot {
    background: #EEEEE6;
    color: #000000;
    display: flex;
    justify-content: space-between;
    font-family: "Medium";
    font-size: calc(15px * var(--scale));
    gap: calc(2rem * var(--scale));
    padding: 0 calc(2rem * var(--scale));
    width: 100%;
}

.footer-title {
    margin-bottom: 0;
}

.footer-subtitle {
    align-items: center;
    display: flex;
    font-size: calc(10px * var(--scale));
    gap: calc(2px * var(--scale));
    flex-direction: row;
}

.nav {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: calc(2rem * var(--scale));
    padding: 0 calc(1rem * var(--scale));
    margin-top: calc(1.05rem * var(--scale));
    margin-left: calc(4.53rem * var(--scale));
}

.nav li.active {
    color: #667BC4;
    background-color: #C7D3FB;
    padding: calc(0.25rem * var(--scale)) calc(0.625rem * var(--scale));
    border-radius: calc(0.6875rem * var(--scale));
}

.nav li:hover {
    color: #667BC4;
}

.nav-menu {
    display: flex;
    width: calc(65.875rem * var(--scale));
    justify-content: center;
    align-items: center;
    gap: calc(2.75rem * var(--scale));
    list-style: none;  
    margin: 0;  
}

.nav a {
    color: #F0F0F0;
    font-family: "Medium";
    text-decoration: none;
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: calc(1.2rem * var(--scale));
}

.menu-icon {
    display: none;
}

.site-logo {
    font-size: calc(5rem * var(--scale));
    width: calc(16.5rem * var(--scale));
    height: calc(7.21875rem * var(--scale));
}

.foot ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: calc(1rem * var(--scale));
}

.foot a {
    color: inherit;
    font-family: "Medium";
    text-decoration: none;
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
    padding: calc(0.25rem * var(--scale));
}

.socials {
    width: auto;
    height: auto;
    object-fit: fill;
}

@media (max-width: 768px) {

    .nav{
        /* margin-top: calc(10rem * var(--scale)); */
        /* display: flex;
        justify-content: space-between;
        align-items: center;
        margin: calc(1rem * var(--scale));
        padding: 0; */

        display: flex;
        justify-content: space-between;
        align-items: center;
        /* margin: calc(8rem * var(--scale)); */
        padding: 0;
        margin: calc(5rem * var(--scale)) calc(5rem * var(--scale));
    }

    .site-logo{
        width: calc(40rem * var(--scale));
        height: auto;
    }


    .menu-icon {
        display: block;
        position: relative;
        /* position: absolute; */
        /* top: 0;
        right: 0; */
        /* transform: translate(-100%, 60%); */
        font-size: calc(8rem * var(--scale));
        cursor: pointer;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        margin-top: 30%;
        gap: calc(8rem * var(--scale));
    }

    .nav-container.active {
        background-color: darkgray;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-container {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 100vh;
        position: absolute;
        top: 0px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav a {
        font-size: calc(6rem * var(--scale));
        text-align: center;
    }

    .socials {
        width: 25px;
        height: 25px;
        object-fit: fill;
    }

    .footer-title {
        font-size: 12px;
    }

    .footer-subtitle-text {
        font-size: 9px;
    }

    .foot {
        flex-direction: column;
        padding: 0 1rem;
        gap: 0;
    }

    .foot ul {
        margin-left: auto;
        gap: 0.5rem
    }
}