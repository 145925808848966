.page-title{
    color: #F0F0F0;
    text-align: center;
    font-family: "Helvetica Neue";
    font-size: 4rem;
    font-weight: 700;
}

.page{
    overflow: hidden;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    overflow: hidden; 
    max-width: 70%;
    width: 100%;
    height: auto; 
    /* margin-left: 20px; */
    margin-right: 20px; 
    align-items: flex-start;
    justify-content: flex-start;
}


.grid-item {
    display: flex;
    padding: 20px;
    align-items: center;
    text-align: center;
    width: auto;
    justify-content: flex-start;
}

.grid-item img {
    width: 300px;
    height: 300px;
}

.this-week {
    font-family: "Montserrat-Medium-Soft";
    display: flex;
    left: 72px;
    margin-left: 72px;
    color: #265999;

    color: #EEB50C;
    font-size: 2.25rem;
    font-weight: 600;
}