* {
    box-sizing: border-box;
}

:root {
    /* dimensionless ratio of (current viewport / 1512px) */
    --scale: 1;
  }

.home {
    display: flex;
    /* justify-content: center; */
    flex-grow: 1;
    flex-direction: column;
    position: relative;
    overflow-x:hidden;

    /* max-width: 1512px;
    margin: 0 auto; */
}

.home-nav{
    
    display: flex;
    align-items: center;
    margin-left: calc(4.5rem * var(--scale));
    margin-top: calc(2.75rem * var(--scale));
}


.home-logo {
    width: calc(22rem * var(--scale));
    height: calc(9.1875rem * var(--scale));
}

.home-nav-menu {
    display: flex;
    width: calc(65.875rem * var(--scale));
    justify-content: center;
    align-items: center;
    gap: calc(2.6875rem * var(--scale));
    list-style: none;
    padding-top:calc(2rem * var(--scale));
}


.home-nav-menu li a {
    text-decoration: none;
    color: #D9D9D9;
    font-family: Medium;
    font-size: calc(1.25rem * var(--scale));
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.home-nav-menu li a:hover {
    color: #667BC4;
}

.home-nav-menu li.active {
    color: #667BC4;
    background-color: #C7D3FB;
    padding: calc(0.25rem * var(--scale)) calc(0.625rem * var(--scale));
    border-radius: calc(0.6875rem * var(--scale));
}

.header {
    color: #F0F0F0;
    padding-bottom: 0;
    margin-bottom: 0;
    text-align: center;
    font-family: "Hard";
    font-size: calc(4rem * var(--scale));
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.header.bottom {
    margin-top: 0;
}

.sub-header {
    /* color: #37383C;
    text-align: center; */

    color: #F0F0F0;
    text-align: center;
    font-family: "Helvetica Neue";
    font-size: calc(1.875rem * var(--scale));
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.heart {
    width: calc(11px * var(--scale));
    height: calc(11px * var(--scale));
}

.btn {
    margin: calc(10px * var(--scale));
    display: flex;
    padding: calc(20px * var(--scale)) calc(40px * var(--scale));
    font-weight: bold;
    /* background-size: 200% auto; */
    font-size: calc(30px * var(--scale));
    color: white;
    /* box-shadow: 0 0 20px #eee; */
    text-decoration: none;
    border-radius: calc(64px * var(--scale));
    text-align: center;
}

.btn-small {
    margin: calc(10px * var(--scale));
    display: flex;
    padding: calc(10px * var(--scale)) calc(20px * var(--scale));
    font-weight: bold;
    background-size: 200% auto;
    font-size: calc(20px * var(--scale));
    color: white;
    /* box-shadow: 0 0 20px grey; */
    text-decoration: none;
    border-radius: calc(64px * var(--scale));
}

.btn-1 {
    background-image: linear-gradient(108deg, #DFC856 19%, #D38745 71%);
    text-align: center;
}

.btn-2 {
    background-image: linear-gradient(to right, #F18F02 0%, #FFB244 50%);
    text-align: center;


}

.btn-plat {
    background: linear-gradient(273deg, rgba(38, 89, 153, 0.80) 3.86%, #C0C0C0 96.11%);
    position: absolute;
    top: 0;
    left: 0;
}

.btn-gold {
    background-image: linear-gradient(108deg, #CFAE45 19.51%, rgba(211, 135, 69, 0.80) 71.52%);
    position: absolute;
    top: 0;
    left: 0;
}
.btn-silver {
    background-image: linear-gradient(99deg, rgba(192, 192, 192, 0.50) 0.49%, #C0C0C0 102.93%);
    position: absolute;
    top: 0;
    left: 0;
}

.btn-bronze {
    background: linear-gradient(97deg, #CD7F32 0.44%, rgba(205, 127, 50, 0.50) 100.44%);
    position: absolute;
    top: 0;
    left: 0;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.btn-1:hover {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
}

.btn-2:hover {
    background-image: linear-gradient(to right, #b16802 0%, #d8963a 50%);
}

.blue_ellipse {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(64.0625rem * var(--scale));
    height: calc(24.74056rem * var(--scale));
    flex-shrink: 0;
    padding-top: calc(2rem * var(--scale));

    /* max-width: 100%;
    max-height: 80%; */
}

.purple_ellipse {
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(57.125rem * var(--scale));
    height: calc(23.83388rem * var(--scale));
    flex-shrink: 0;
    padding-top: calc(2rem * var(--scale));
}

.group {
    position: relative;
    margin-bottom: 0;
    width: 100%;
}

.images {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: calc(2.56rem * var(--scale));
}

/* 
@media (max-width: 768px) {
    .home-nav {
        flex-direction: column;
        margin: calc(1rem * var(--scale));
        align-items: flex-start;
    }

    .home-logo {
        width: calc(12rem * var(--scale));
        height: auto;
    }

    .home-nav-menu {
        display: none;
    }

    .home-content {
        margin-top: calc(4rem * var(--scale));
        padding: 0 calc(1rem * var(--scale));
    }

    .header {
        font-size: calc(2rem * var(--scale));
        padding: calc(0.5rem * var(--scale));
    }

    .header.bottom {
        font-size: calc(2.5rem * var(--scale));
    }

    .sub-header {
        font-size: calc(1.2rem * var(--scale));
        margin: calc(1rem * var(--scale));
        padding: 0 calc(1rem * var(--scale));
    }

    .images {
        flex-direction: column;
        margin-top: calc(2rem * var(--scale));
    }

    .blue_ellipse, .purple_ellipse {
        display: none;
    }

    .group {
        width: 100%;
        height: auto;
        margin-top: calc(2rem * var(--scale));
    }

    .buttons {
        flex-direction: column;
        gap: calc(1rem * var(--scale));
    }

    .btn {
        width: calc(10rem * var(--scale));
        font-size: calc(1rem * var(--scale));
        padding: calc(0.625rem * var(--scale));
    }
} */